if (!sessionStorage.getItem("pageCount")) {
  sessionStorage.setItem("pageCount", 1);
} else {
  sessionStorage.setItem("pageCount", parseInt(sessionStorage.getItem("pageCount")) + 1);
}

if (!sessionStorage.getItem("enter")) {
  sessionStorage.setItem("enter", window.location.pathname);
}

// goalOne: User performs a search after entering via a guide page
if (!sessionStorage.getItem("goalOneTriggered")) {
  sessionStorage.setItem("goalOneTriggered", "false");
}

const triggerGoalOne = function () {
  if (
    sessionStorage.getItem("enter").match(/\/guides\/(.*)/) &&
    sessionStorage.getItem("goalOneTriggered") == "false"
  ) {
    // console.log("goalOneTriggered");
    plausible("User performs a search after entering via a guide page");
    sessionStorage.setItem("goalOneTriggered", "true");
  }
};

// goalTwo: User visits a second guide after entering via a guide page
if (!sessionStorage.getItem("goalTwoTriggered")) {
  sessionStorage.setItem("goalTwoTriggered", false);
}

if (
  sessionStorage.getItem("enter").match(/\/guides\/(.*)/) &&
  window.location.pathname.match(/\/guides\/(.*)/) &&
  sessionStorage.getItem("goalTwoTriggered") == "false"
) {
  // console.log("goalTwoTriggered");
  plausible("User visits a second guide after entering via a guide page");
  sessionStorage.setItem("goalTwoTriggered", true);
}

// goalThree: User visits a guide after using search after entering via homepage
if (
  sessionStorage.getItem("enter") == "/" &&
  sessionStorage.getItem("pageCount") == "2" &&
  window.location.pathname == "/guides"
) {
  sessionStorage.setItem("goalThreeActive", true);
}

if (
  sessionStorage.getItem("goalThreeActive") == "true" &&
  sessionStorage.getItem("pageCount") == "3" &&
  window.location.pathname.match(/\/guides\/(.*)/)
) {
  // console.log("goalThreeTriggered");
  plausible("User visits a guide after using search after entering via homepage");
  sessionStorage.removeItem("goalThreeActive");
}

// goalFour: User visits a Guide after performing a search from any page on the site
const armGoalFour = function () {
  sessionStorage.setItem("goalFourActive", true);
};

if (/[?&]searchTerm=/.test(location.search)) {
  armGoalFour();
}

if (sessionStorage.getItem("goalFourActive") == "true" && window.location.pathname.match(/\/guides\/(.*)/)) {
  plausible("User visits a Guide after performing a search from any page on the site");
  sessionStorage.removeItem("goalFourActive");
}

// goalFive: User visits subsequent Guides
if (window.location.pathname.match(/\/guides\/(.*)/)) {
  let guidesViewed = sessionStorage.getItem("guidesViewedCount");
  if (!guidesViewed) {
    sessionStorage.setItem("guidesViewedCount", 1);
  } else {
    guidesViewed++;
    sessionStorage.setItem("guidesViewedCount", guidesViewed);
  }

  if(guidesViewed == 2) {
    plausible("User visits a second Guide");
  } else if(guidesViewed == 3) {
    plausible("User visits a third Guide");
  } else if(guidesViewed == 4) {
    plausible("User visits a fourth Guide");
  } else if(guidesViewed == 5) {
    plausible("User visits a fifth Guide");
  } else if(guidesViewed == 6) {
    plausible("User visits a sixth Guide");
  }
}

// goalSix: User clicks an outbound link on a Guide page
if (window.location.pathname.match(/\/guides\/(.*)/)) {
  const anchorLinks = document.querySelectorAll("a");

  anchorLinks.forEach((link) => {
    if (link.getAttribute("target") === "_blank") {
      link.addEventListener("click", (event) => {
        event.preventDefault();
        const url = link.getAttribute("href");
        plausible("User clicks an outbound link on a Guide page");
        window.open(url, "_blank");
      });
    }
  });
}
